import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import { generateclass } from '../apis/api';
import { readFromLocalStorage, writeToLocalStorage } from '../apis/helperfunctions';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'black', // Border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black', // Border color when focused
    },
  },
  '& .MuiInputLabel-root': {
    color: 'black', // Default label color
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'black', // Label color when focused
  },
}));






export default function GenerateClass({allchoices, closegeneration}) {
  const [formField, setfromField] = React.useState({
    yoga_name: "Normal",
    age_group: "For All",
    level: "Beginner Level",
    duration: "20 mins",
    objective: "General Fitness"
  });
 
  const [height, setHeight] = React.useState(0)
  
  const [focusCheckbox, setfocusCheckbox] = React.useState({
    "Full Body": true
  })
  
  const [propsCheckbox, setpropsCheckbox] = React.useState({
  
  })
  
  // To update a specific property, you can use the spread operator:
  const updateFormFiled  = (key)=>(event) => {
    setfromField(prevfield => ({
      ...prevfield,
      [key]: event.target.value
    }));
  };
  
  
  React.useEffect(()=>{
    const height = readFromLocalStorage('height')
    setHeight(height)

  }, [])


  const toTitleCase = (str)=>(
                           str.toLowerCase()                  
                            .split(' ')                         
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
                            .join(' ')
  )

  const focushandler = (event) => {
    setfocusCheckbox({
      ...focusCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  const propshandler = (event) => {
    setpropsCheckbox({
      ...propsCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  const heightHandler = (event)=>{
    setHeight(event.target.value)
    console.log('height change')
  }
  
  const generateClassSequences = (event)=>{
    // Filter focusCheckbox based on your criteria
  const filteredFocusCheckbox = Object.fromEntries(
    Object.entries(focusCheckbox).filter(([key, value]) => value) // Example: Keep only true values
  );

  // Filter propsCheckbox based on your criteria
  const filteredPropsCheckbox = Object.fromEntries(
    Object.entries(propsCheckbox).filter(([key, value]) => value) // Example: Keep only true values
  );

  // console.log(filteredFocusCheckbox, filteredPropsCheckbox);
  const pose_correcter = (name)=>{
    if (name === 'Balasana (Childs Pose)'){
      return "Balasana (Child's Pose)"
    }else if (name == "Bharadvaja’S Twist"){
      return "Bharadvaja’s Twist"
    }
    return name
  }

  writeToLocalStorage('height', height)

  generateclass({...formField, props: Object.keys(filteredPropsCheckbox), focus: Object.keys(filteredFocusCheckbox)}).then((data)=>{
    console.log(data)
    if (!data){
      return
    }
    if (data.status === 200) {
      // Check if data.data.next_poses is an array before mapping
      if (Array.isArray(data.data.next_poses)) {
        // Map over the array and apply pose_correcter to each item
        const correctedPoses = data.data.next_poses.map(pose_correcter);
    
        // Write the corrected poses to local storage
        writeToLocalStorage("class_sequences", correctedPoses);
        closegeneration('abc')
      } else {
        console.error("Expected next_poses to be an array");
      }
      
      return; // Exit the function if status is 200
    }
    console.log(data)

  })

  }




  return (
    <>
    <FormLabel component="h5">Generate Sequences Class</FormLabel>
    <Box>
            
            <InputLabel htmlFor="type_exercise" required>Yoga Pose Type</InputLabel>
             <Select
               fullWidth
               labelId="type_exercise_label"
               id="type_exercise"
               label="Goal"
               value={formField['yoga_name']}
               onChange={updateFormFiled('yoga_name')}
             >
                     {allchoices && allchoices['yoga name'] && allchoices['yoga name'].map((n, i) => (
                        <MenuItem value={n} key={i}>
                             {toTitleCase(n)}
                        </MenuItem>
                      ))}
             </Select>
       </Box>
      
      
      
      
      <Box>
       <FormLabel component="legend" required>Focus</FormLabel>
        <FormGroup>
        {allchoices && allchoices['focus'] && allchoices['focus'].map((n, i) => {
                      
                 return(
                      <FormControlLabel
                        control={
                          <Checkbox checked={focusCheckbox[n]} color='success' onChange={focushandler} name={n} />
                        }
                     label={toTitleCase(n)
                        }
                      />
                      )})
        }

         
        </FormGroup>
        </Box>

         
       <Box>
            
            <InputLabel htmlFor="type_exercise" required>Age Group</InputLabel>
             <Select
               fullWidth
               labelId="type_exercise_label"
               id="type_exercise"
               label="Goal"
               value={formField['age_group']}
               onChange={updateFormFiled('age_group')}
             >
               {allchoices && allchoices['age group'] && allchoices['age group'].map((n, i) => (
                        <MenuItem value={n} key={i}>
                             {toTitleCase(n)}
                        </MenuItem>
                      ))}
             </Select>
       </Box>  
       
        
       <Box>
            
             <InputLabel htmlFor="type_exercise" required>Level</InputLabel>
              <Select
                fullWidth
                labelId="type_exercise_label"
                id="type_exercise"
                label="Goal"
                value={formField['level']}
                onChange={updateFormFiled('level')}
             >
                {allchoices && allchoices['Level'] && allchoices['Level'].map((n, i) => (
                        <MenuItem value={n} key={i}>
                             {toTitleCase(n)}
                        </MenuItem>
                      ))}
              </Select>
        </Box>  
      
         
       <Box>
            
            <InputLabel htmlFor="type_exercise" required>Objective</InputLabel>
             <Select
               fullWidth
               labelId="type_exercise_label"
               id="type_exercise"
               label="Goal"
               value={formField['objective']}
               onChange={updateFormFiled('objective')}
             >
               {allchoices && allchoices['objective'] && allchoices['objective'].map((n, i) => (
                        <MenuItem value={n} key={i}>
                             {toTitleCase(n)}
                        </MenuItem>
                      ))}
             </Select>
       </Box>  
     
         
       
       <Box>
            
             <InputLabel htmlFor="type_exercise" required>Duration</InputLabel>
              <Select
                fullWidth
                labelId="type_exercise_label"
                id="type_exercise"
                label="Goal"
                value={formField['duration']}
               onChange={updateFormFiled('duration')}
             >
               {allchoices && allchoices['duration'] && allchoices['duration'].map((n, i) => (
                        <MenuItem value={n} key={i}>
                             {toTitleCase(n)}
                        </MenuItem>
                      ))}
              </Select>
        </Box>  

        <Box>
       <FormLabel component="legend" required>Props (optional)</FormLabel>
        <FormGroup>
        {allchoices && allchoices['props'] && allchoices['props'].map((n, i) => {
                      
                      return(
                           <FormControlLabel
                             control={
                               <Checkbox checked={propsCheckbox[n]} color='success' onChange={propshandler} name={n} />
                             }
                          label={toTitleCase(n)
                             }
                           />
                           )})
             }
        </FormGroup>
        </Box>
         
        <Box
      sx={{
        margin: "10px 0px",
      }}
    >
        <CustomTextField id="outlined-basic" label="Your Height (CM)" variant="outlined"  
        
        value = {height}
        onChange={heightHandler}/>
     

        </Box>

        

          <Box>
            <Button 
              variant='contained' 
              fullWidth 
              sx={{ backgroundColor: 'white',
                   color: 'green' }}

              onClick={generateClassSequences}     
            >
              Generate
            </Button>

          </Box>
        
     </>
  );
}
