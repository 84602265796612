import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import FrameViewer from './capture_frame';
import Typography from '@mui/material/Typography';
import { readFromLocalStorage, writeToLocalStorage } from '../apis/helperfunctions';
import {Button} from '@mui/material';
import { prettyDOM } from '@testing-library/react';

const write_ht_px_ratio = (ratio)=>{
  writeToLocalStorage('ht_px_ratio', ratio)
  writeToLocalStorage('ht_px_ratio_exp', Math.floor(Date.now() / (1000 * 60)))
}

const write_ht_ideal_doer_ratio = (ratio)=>{
  writeToLocalStorage('ht_ideal_doer_ratio', ratio)
  writeToLocalStorage('ht_ideal_doer_ratio_exp', Math.floor(Date.now() / (1000 * 60)))
}


const FreeStyle = () => {
   const videoRef = useRef(null);
   const canvasRef = useRef(null);
   const VideoStreamRef = useRef(null);
   const [responseFrame, setResponseFrame] = useState(null);
   const ws = useRef(null);
   const isStart = useRef(false)
  
   const [isPermission, setPermission] = useState(false)
   const [currentExerciseStyle, setcurrentExerciseStyle] = useState('childsplay')
   const backendWsUrl = process.env.REACT_APP_BACKEND_WS_URL;
   const backendURL = process.env.REACT_APP_BACKEND_URL;
   const [frameNumber, setFrameNumber] = useState(0);
   const frameRate  = 30
   const [speed, setspeed] = useState({})
   const [angle, setangle] = useState({})
   const is_cal_ratio  = useRef(false)
   const ht_px_ratio = useRef(0)
   const ht_ideal_doer_ratio = useRef(0)
   const height = useRef(0)
   const [class_seq, setclass_seq] = useState([])
  


   useEffect(() => {
    // Initialize camera stream
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            setPermission(false)
          }
        })
        .catch(error => {
          console.error('Error accessing camera:', error)
          setPermission(true)
        });
    }
  
  }, []);

  const getFrameNumber = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime; // Time in seconds
      const currentFrame = Math.floor(currentTime * frameRate); // Approximate frame number
      setFrameNumber(currentFrame);
    }
  };



  const isStartHandler = (val)=> {
    if (val){
      console.log('Started', val)
    }else{
      console.log('Stoped', val )
    }
    isStart.current = val
  };

  useEffect(() => {
    // Open WebSocket connection
    const connect_websocket = ()=>{
      if (!isStart.current){
      closeConnection()
    }

    console.log('Current Pose when at start', currentExerciseStyle)
    
    ws.current = new WebSocket(`${backendWsUrl}/freestyle/${currentExerciseStyle}/`);
    
    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };

    ws.current.onmessage = (event) => {
      
      // Handle response frame from server
      const receivedData = JSON.parse(event.data); // Parse the JSON data from server
      const receivedFrame = receivedData.frame;

      if (!receivedFrame){
         connect_websocket()
         console.log('web socket conneting again')
         return 
      } // Extract the frame from received data
      
      if (receivedData.ht_px_ratio   > 0){
        // console.log(receivedData)
        write_ht_px_ratio(receivedData.ht_px_ratio)
        is_cal_ratio.current = false
        ht_px_ratio.current = receivedData.ht_px_ratio
      }

      if (receivedData.ht_ideal_doer_ratio   > 0){
        
        write_ht_px_ratio(receivedData.ht_ideal_doer_ratio)
        // is_cal_ratio.current = false
        ht_ideal_doer_ratio.current = receivedData.ht_ideal_doer_ratio
      }
      if(receivedData.angles){
        setangle(receivedData.angles)
      }
      if(receivedData.speed){
        setspeed(receivedData.speed)
      }

      setResponseFrame(receivedFrame);
      
    };
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
    
    }
    connect_websocket()
    
    
    return () => {
      // Clean up WebSocket connection
      ws.current.close();
    };
  }, [isStart.current]);


  const closeConnection = ()=>{
    if (ws.current){
      ws.current.close()
      console.log('Connection closed')
   
    }
  }

  useEffect(()=>{
    closeConnection()
   //  isStart.current = false
   const connect_websocket = ()=>{
     if (!isStart.current){
     closeConnection()
   }
   ws.current = new WebSocket(`${backendWsUrl}/freestyle/${currentExerciseStyle}/`);
   
   ws.current.onopen = () => {
     console.log('WebSocket connected');
   };

   ws.current.onmessage = (event) => {
     
     // Handle response frame from server
     const receivedData = JSON.parse(event.data); // Parse the JSON data from server
     const receivedFrame = receivedData.frame;
     

     
     if (!receivedFrame){
        connect_websocket()
        console.log('web socket conneting again')
        return 
     } // Extract the frame from received data

     if (receivedData.ht_px_ratio   > 0){
       console.log(receivedData)
       
       write_ht_px_ratio(receivedData.ht_px_ratio)
       ht_px_ratio.current = receivedData.ht_px_ratio
       is_cal_ratio.current = false
     }

     if (receivedData.ht_ideal_doer_ratio   > 0){
        
      write_ht_px_ratio(receivedData.ht_ideal_doer_ratio)
      // is_cal_ratio.current = false
      ht_ideal_doer_ratio.current = receivedData.ht_ideal_doer_ratio
    }

     if(receivedData.angles){
       setangle(receivedData.angles)
     }
     if(receivedData.speed){
       setspeed(receivedData.speed)
     }
     setResponseFrame(receivedFrame);
     
   };
   ws.current.onerror = (error) => {
     console.error('WebSocket error:', error);
   };
   
   ws.current.onclose = (event) => {
     if (!event.wasClean) {
       if (currentExerciseStyle){
         console.log('WebSocket closed unexpectedly, reconnecting...');
         setTimeout(() => connect_websocket(), 1000);
       
       }
     }
   };
 }
   connect_websocket()

   let h = readFromLocalStorage('height')

   if(h != null){
     height.current = Number(h)
   }
   let ht_px_ratio_s = readFromLocalStorage('ht_px_ratio')
   let ht_px_ratio_exp = readFromLocalStorage('ht_px_ratio_exp')
   let ht_ideal_doer_ratio_s = readFromLocalStorage('ht_px_ratio')
   let expired  = Math.floor(Date.now() / (1000 * 60)) - ht_px_ratio_exp
   if (ht_px_ratio_s == undefined || ht_px_ratio_s == null ||  expired >= class_seq.length * 2){
     is_cal_ratio.current = true
   }
   if (ht_px_ratio_s != null || ht_px_ratio_s != undefined){
       ht_px_ratio.current = ht_px_ratio_s
   }
   if (ht_ideal_doer_ratio_s != null || ht_ideal_doer_ratio_s != undefined){
    ht_ideal_doer_ratio.current = ht_ideal_doer_ratio_s
   }


 }, [currentExerciseStyle])



 const captureFrame = async() => {
  // Ensure video and canvas refs are valid

  if (videoRef.current && canvasRef.current) {
    getFrameNumber()
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    // Convert canvas image to base64
    const imageData = canvas.toDataURL('image/jpeg');
    // console.log(imageData)

    // Send frame data to server

    if ((ws.current.readyState === WebSocket.OPEN)  && (isStart.current)){
      ws.current.send(JSON.stringify({frameData: imageData,
                                      is_cal_ratio: is_cal_ratio.current,
                                      height: height.current,
                                      ht_px_ratio: ht_px_ratio.current,
                                      cal_angle: true,
                                      cord_index: frameNumber,
                                      ht_ideal_doer_ratio: ht_ideal_doer_ratio.current
                                      }));
      // console.log('frame send to sever')
     
    }else{
      setResponseFrame(imageData)
    
    } 
    
  }
  
};

useEffect(() => {
  const interval = setInterval(() => {
    captureFrame()
  //  console.log('Interval is running')
   
  }, 400); 

  return () => clearInterval(interval); 
}, []); 

  
  return (
    <div style={{alignItems: 'center'}}>
        <h1>Free Style Exercise</h1>
        <video ref={videoRef} style={{ display: 'none' }} width="100%" height="auto" autoPlay></video>
        <canvas ref={canvasRef} style={{ display: 'none' }} width={410} height={720} />
       
        

        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={12} sm={6}>
              <FrameViewer frame={responseFrame} />
              {isPermission ? (
              <Typography color="error">Please grant permission to access your camera.</Typography>
              ) : ' ' }
              </Grid>
             <Grid item xs={12} sm={6}>
             <video ref={VideoStreamRef} 
                width="410"
                height="auto"
                autoPlay
                muted
                src={`${backendURL}/video`}
                />
            </Grid>
           
        </Grid>
        <Grid container spacing={2} justifyContent="center" alignItems="center" margin={2}>
          <Grid>
              
              { !isStart.current ? (<Button onClick={() => isStartHandler(true)} variant="contained">
                        Start
                </Button>) : (<Button onClick={() => isStartHandler(false)} variant="contained" color='error'>
                        Stop
                </Button>)}
          </Grid>
        </Grid>

    </div>
  );
};

export default FreeStyle;
