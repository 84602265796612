import React from 'react';
import { Grid, Paper, Typography, Box  } from '@mui/material';


const DisplayValues = ({angle, speed}) => {
    return (
        <div style={{marginTop: 20}}>
        <Grid container spacing={2}>
            {/* First Grid for Data1 */}
            <Grid  item xs={12} sm={6}>
                <Paper elevation={2} style={{ padding: 10, width: 300, height: '100%' }}>
                    <Typography variant="h4" gutterBottom>
                        Speed
                    </Typography>
                    {!speed && (
                        <Typography variant='body1'> Speed is not Calculating</Typography>
                    )}
                    {speed && Object.entries(speed).map(([key, value]) => (
                         <Box key={key} display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                         <Typography variant="body1">{key} :</Typography>
                         <Typography variant="body2">{Math.floor(value[0])} cm/s</Typography>
                     </Box>
                    ))}
                </Paper>
            </Grid>

            {/* Second Grid for Data2 */}
            <Grid item xs={12} sm = {6}>
                <Paper elevation={2} style={{ padding: 10, width: 300, height: '100%' }}>
                    <Typography variant="h4" gutterBottom>
                        Angle
                    </Typography>
                    {!angle && (
                        <Typography variant='body1'> Angle is not Calculating</Typography>
                    )}
                    {angle && Object.entries(angle).map(([key, value]) => (
                        <Box key={key} display="flex" justifyContent="space-between" alignItems="center" marginBottom={1}>
                        <Typography variant="body1">{key} :</Typography>
                        <Typography variant="body2">{Math.floor(value)}  &deg;C</Typography>
                        </Box>
                    ))}
                </Paper>
            </Grid>
        </Grid>
        </div>
    );
};

export default DisplayValues;
