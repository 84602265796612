import React from 'react';
import { Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import routing components
import VideoDisplay from './component/VideoDisplay';
import Header from './component/Header';
import Footer from './component/Footer';
import FreeStyle from './component/freestyle';

const theme = createTheme();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header />
        <Container maxWidth="md" style={{ marginTop: 40 }}>
          <Routes>
            {/* Define different routes */}
            <Route path="/" element={<VideoDisplay/>} />  {/* Home page */}
            <Route path="/freestyle" element={<FreeStyle />} /> {/* Video page */}
          </Routes>
        </Container>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
