import React from 'react';
import { AppBar, Toolbar, Typography,Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <AppBar position="static">
            <Toolbar style={{ display: 'flex', justifyContent: 'center' }}>
            <Box display="flex" alignItems="center">
                <img src="/Ocius-Logo.png" alt="Company Logo" style={{ width: 120, marginRight: 15, marginLeft:20 }}/>
                <Typography variant="h6" component="div" margin={1}>
                    Ocius Technologies
                </Typography>
                   <Button 
                    color="inherit" 
                    component={NavLink} 
                    to="/" 
                    end
                    style={({ isActive }) => ({
                        marginLeft: '20px',
                        color: isActive ? '#f50057' : 'yellow',  // Pink if active, default if not
                        fontWeight: isActive ? 'bold' : 'normal',  // Bold if active
                        textDecoration: isActive ? 'underline' : 'none',  // Underline if active
                        fontSize:16
                    })}
                    >
                    Home
                    </Button>
                    <Button 
                    color="inherit" 
                    component={NavLink} 
                    to="/freestyle" 
                    style={({ isActive }) => ({
                        color: isActive ? '#f50057' : 'yellow',
                        fontWeight: isActive ? 'bold' : 'normal',
                        textDecoration: isActive ? 'underline' : 'none',
                        fontSize: 16,
                        marginLeft: "20px"
                    })}
                    >
                    Free Style
                    </Button>
            </Box>    
            </Toolbar>
        </AppBar>
    );
};

export default Header;